import { Grid, Typography, ButtonBase as RippleEffect } from '@mui/material';
import React from 'react';
import { ApolloError } from '@apollo/client';
import { PlatformLoadingSkeleton } from './Platform/PlatformLoadingSkeleton';
import { PlatformCard } from './Platform';
import { AllPlatformsQuery } from '@/__generated__/graphql';

type PlatformProps = {
  loading: boolean;
  selectedPlatforms: AllPlatformsQuery['allPlatforms'];
  error: ApolloError | undefined;
  handlePlatformChange: (id: string, name: string) => void;
};

export const GridView = ({
  loading,
  selectedPlatforms,
  error,
  handlePlatformChange,
}: PlatformProps) => {
  return (
    <Grid sx={{ my: 2 }} justifyContent="center" container spacing={3.5} data-testid="platforms">
      {loading && <PlatformLoadingSkeleton />}
      {((!loading && selectedPlatforms.length === 0) || error) && (
        <Typography variant="body1" fontSize="2.6rem" my={10} fontWeight={600}>
          No platforms found
        </Typography>
      )}
      {!loading &&
        selectedPlatforms.length > 0 &&
        selectedPlatforms.map(({ name, id, platformCardInsights, coverImagePath }) => (
          <Grid item lg={3} md={3} sm={6} xs={12} key={id}>
            <RippleEffect
              component="div"
              onClick={() => handlePlatformChange(id, name)}
              sx={{ borderRadius: '10px', display: 'block' }}
            >
              <PlatformCard
                name={name}
                subHeading={''}
                platformCardInsights={platformCardInsights}
                imageUrl={coverImagePath}
              ></PlatformCard>
            </RippleEffect>
          </Grid>
        ))}
    </Grid>
  );
};
