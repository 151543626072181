import React, { useCallback, useMemo, useState } from 'react';
import { matchSorter } from 'match-sorter';
import { Search } from '@mui/icons-material';
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Autocomplete,
  InputLabel,
} from '@mui/material';
import { useRouter } from 'next/router';
import { PlatformsContainer, MainContainer, PlatformTotalCount } from './styles';
import { useAllRegionsQuery } from '@/__generated__/graphql';
import { GridView } from './GridView';
import { AllPlatformsQuery, useAllPlatformsQuery } from '@/__generated__/graphql';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as state from '@/state/atoms';
import { titleCase } from '@/utils';

type PlatformDisplay = 'coating' | 'health';

export const Platforms = () => {
  const [selectedContinent, setSelectedContinent] = useState('global');
  const [selectedPlatforms, setSelectedPlatforms] = useState<AllPlatformsQuery['allPlatforms']>([]);
  const [searchPlatform, setSearchPlatform] = useState<string>('');
  const setPlatformName = useSetRecoilState(state.platformName);

  const [regionId, setRegionId] = useState<string | undefined>('');
  const [platformSelectedFilter, setPlatformSelectedFilter] = useRecoilState(
    state.platformSelectedFilter
  );
  const platformsFilters = useRecoilValue(state.platformsFilters);

  const router = useRouter();

  const { loading, error, data } = useAllPlatformsQuery({
    variables: {
      regionId,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: regionsData } = useAllRegionsQuery({ fetchPolicy: 'cache-first' });

  const allRegions = useMemo(() => {
    return regionsData?.allRegions;
  }, [regionsData]);

  const allPlatforms = useMemo(() => {
    const platforms = data?.allPlatforms ?? [];
    setSelectedPlatforms(platforms);
    return platforms;
  }, [data]);

  const handlePlatformChange = useCallback(
    (id: string, name: string) => {
      setPlatformName(name);
      const pathname = `/platform-assets/${id}`;
      router.push({
        pathname,
      });
    },
    [router, setPlatformName]
  );

  const handleChangeContinent = (event: SelectChangeEvent) => {
    const continent = event.target.value;
    setSearchPlatform('');
    setSelectedContinent(continent);
    const id = allRegions?.find((region) => region.name.toLowerCase() === continent)?.id;
    setRegionId(id);
    setSelectedPlatforms(
      continent === 'global'
        ? allPlatforms
        : allPlatforms.filter((platform) =>
            platform.name.toLowerCase().includes(continent.toLowerCase())
          )
    );
  };

  const handleChangePlatformFilter = useCallback(
    (event: SelectChangeEvent) => {
      const filter = event.target.value as PlatformDisplay;
      setSearchPlatform('');
      setPlatformSelectedFilter(filter);
    },
    [setPlatformSelectedFilter]
  );

  const handleSearchPlatform = (searchTerm: string) => {
    setSearchPlatform(searchTerm);

    const filteredGridPlatforms = allPlatforms.filter(
      (platform) =>
        matchSorter([platform.name], searchTerm, {
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        }).length > 0
    );

    setSelectedPlatforms(filteredGridPlatforms);
  };

  return (
    <MainContainer>
      <PlatformsContainer>
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          flex={1}
          spacing={3}
          alignItems="center"
          mt={7}
        >
          <Box sx={{ minWidth: { sm: '100%', md: '195px' } }}>
            <FormControl fullWidth>
              <InputLabel id="select-continent-label">Region</InputLabel>
              <Select
                inputProps={{ sx: { pt: '10px', pb: '7px' } }}
                value={selectedContinent}
                onChange={handleChangeContinent}
                data-testid="select-continent-dropdown"
                label="Region"
              >
                <MenuItem value="global">Global</MenuItem>
                {allRegions?.map((region) => (
                  <MenuItem key={region.id} value={region.name.toLowerCase()}>
                    {titleCase(region.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: { sm: '100%', md: '195px' } }}>
            <FormControl fullWidth>
              <InputLabel id="select-filter-label">Metrics</InputLabel>
              <Select
                inputProps={{ sx: { pt: '10px', pb: '7px' } }}
                value={platformSelectedFilter}
                onChange={handleChangePlatformFilter}
                data-testid="select-filter-dropdown"
                label="Metrics"
              >
                {platformsFilters?.map((filter, index) => (
                  <MenuItem key={index} value={filter.toLowerCase()}>
                    {titleCase(filter)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box flex={1} sx={{ width: '100%' }}>
            <Autocomplete
              options={[...new Set(allPlatforms.map((item) => item.name))]}
              onInputChange={(event, newValue) => handleSearchPlatform(newValue ?? '')}
              freeSolo
              forcePopupIcon
              clearOnEscape
              filterOptions={(options, { inputValue }) => matchSorter(options, inputValue)}
              renderInput={(parameters) => (
                <TextField
                  {...parameters}
                  data-testid="search-platform-input"
                  sx={{
                    width: { md: '100%', xl: '640px' },
                  }}
                  size="small"
                  value={searchPlatform}
                  placeholder="Search a platform"
                  variant="outlined"
                  InputProps={{
                    ...parameters.InputProps,
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start" sx={{ marginLeft: '8px' }}>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Stack direction="row" spacing={2} alignItems="center" data-testid="platforms-count">
            <>
              <Typography fontSize="1.4rem">Platforms found</Typography>
              <PlatformTotalCount fontSize="1.6rem" fontWeight={700}>
                {selectedPlatforms.length}
              </PlatformTotalCount>
            </>
          </Stack>
        </Stack>

        <GridView
          loading={loading}
          selectedPlatforms={selectedPlatforms}
          error={error}
          handlePlatformChange={handlePlatformChange}
        />
      </PlatformsContainer>
    </MainContainer>
  );
};
